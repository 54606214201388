<template>
  <div class="w-100 update">
    <div class="content">
      <el-card class="box-card">
        <div class="mod-title first-title">
          修改信源监测方案
        </div>
        <div class="box-shadow"></div>
        <div class="pad-lr-60 mar-t-30 ov-h">
          <el-form :inline="true" :model="data" class="demo-form-inline">
            <el-form-item label="方案名称">
              <el-input
                class="w-200"
                v-model="data.name"
                placeholder="请输入方案名称"
                maxlength="10"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-form>
          <h3 class="sub-title mar-t-10">
            监测信源（<var :class="{ 'cor-fc5': data.exps.length > 30 }">{{
              data.exps.length
            }}</var
            >/30）
          </h3>
          <el-button
            type="primary"
            round
            class="single-button mar-t-10"
            @click="addSource"
            >新增信源</el-button
          >
          <div class="mod-list lh-60" v-if="data.exps.length >= 1">
            <ul>
              <li>
                <h3>
                  <span class="num-tit">序号</span>
                  <span class="mod-list-title1">来源</span>
                  <span class="mod-list-title1">作者</span>
                  <span class="mod-list-title2">网址</span>
                  <span class="tag">媒体类型</span>
                  <span class="tag">状态</span>
                  <span class="tag">删除</span>
                </h3>
              </li>
              <li v-for="(row, index) in data.exps" :key="index">
                <span class="num bg-555" v-if="index == 0">1</span>
                <span class="num bg-689" v-else-if="index == 1">2</span>
                <span class="num bg-8ea" v-else-if="index == 2">3</span>
                <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                <span class="mod-list-title1">{{ getName(row) || '--' }}</span>
                <span class="mod-list-title1">{{
                  getAuthor(row) || '--'
                }}</span>
                <span class="mod-list-title2" @click="openLink(row)">{{
                  getLink(row) || '--'
                }}</span>
                <span class="tag">{{ row.category }}</span>
                <span class="tag" v-if="row.state === 'succ'">{{
                  stateMap[row.state]
                }}</span>
                <span class="tag cor-fc5" v-else>{{
                  stateMap[row.state]
                }}</span>
                <span class="tag">
                  <i class="iconfont icon-shanchu" @click="drop(index)"></i>
                </span>
              </li>
            </ul>
          </div>
          <div>
            <el-checkbox v-model="checked">此方案对其他账号可见</el-checkbox>
          </div>
          <div class="button-group mar-t-45 mar-b-40">
            <el-button
              type="primary"
              round
              @click="update"
              v-loading="loading"
              :disabled="loading"
              >修改方案</el-button
            >
            <el-button round @click="back">取消修改</el-button>
          </div>
        </div>
      </el-card>

      <source-dialog ref="sourceDialog" :limit="30" @add="toList" />
    </div>
  </div>
</template>
<script type="application/javascript">
import sourceDialog from '@components/common/source-dialog.vue';
import { updateSourcePlan } from '@/api/source';
import {
  sourceName,
  sourceLink,
  sourceAuthor,
  sourceOpenLink
} from '@/utils/helpers';
export default {
  name: 'update-source-plan',
  components: {
    sourceDialog
  },
  data() {
    return {
      data: {
        name: '',
        exps: []
      },
      stateMap: {
        succ: '成功',
        wait: '待审核',
        fail: '审核失败'
      },
      loading: false,
      checked: false
    };
  },
  mounted() {
    if (this.$route.params.hasOwnProperty('data')) {
      const data = JSON.parse(this.$route.params.data);
      Object.assign(this.data, {
        name: data.name,
        exps: data.exps,
        id: data.id
      });
      this.checked = data.public === 'open';
    } else {
      this.$message.warning('非法进入修改入口');
      this.$router.push({ name: 'source' });
    }
  },
  methods: {
    getName(row) {
      return sourceName(row);
    },
    getLink(row) {
      return sourceLink(row);
    },
    getAuthor(row) {
      return sourceAuthor(row);
    },
    openLink(row) {
      sourceOpenLink(row);
    },
    addSource() {
      this.$refs['sourceDialog'].show(this.data.exps);
    },
    toList(data) {
      this.data.exps = this.data.exps.concat(data);
    },
    drop(index) {
      this.data.exps.splice(index, 1);
    },
    update() {
      const { data } = this;
      if (data.name.length < 1) {
        this.$message.warning('方案名称不能为空');
        return false;
      }

      if (data.exps.length < 1) {
        this.$message.warning('请先选择要添加的信源，不能为空');
        return false;
      }

      if (data.exps.length > 30) {
        this.$message.warning('已选信源数量超过限制，请减少已选信源数量');
        return false;
      }

      data.public = this.checked ? 'open' : 'close';
      this.loading = true;
      updateSourcePlan(data)
        .then(res => {
          if (res.data.state) {
            this.$message.success('更新信源方案成功');
            this.back();
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
          this.$message.error('修改信源失败，服务错误');
          window.console.error(e);
        });
    },
    back() {
      this.$router.push({ name: 'source', params: { index: 'plan' } });
    }
  }
};
</script>
<style scoped>
.update .first-title {
  margin: 3px 0 23px 40px;
}
.update >>> .el-form-item {
  display: block;
}
.update >>> .el-form-item__label {
  font-weight: 600;
}
.update >>> .el-input--small .el-input__inner {
  height: 35px;
  line-height: 35px;
}
.update .sub-title {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}
.update .sub-title var {
  font-weight: 600;
}
.update .num-tit {
  width: 100px;
}
.update .num {
  margin-right: 82px;
}
.update .mod-list-title1,
.update .mod-list-title2,
.update .tag {
  width: 14%;
}
</style>
